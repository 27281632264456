import React from 'react'
// import { Link } from 'react-router-dom'
import Account from 'common/navMain/account'
import css from 'common/navMain/styles'

// wrapped in <div> so the blue bar can span 100% width
const NavMain = () => (
  <div className={css.navMain}>
    <nav>
      <a href="/">Search</a>
      <a href="/resources">Resources</a>
      <a href="/contact-hardinhomes-fort-knox-agents">Contact</a>
      <Account />
    </nav>
  </div>
)

export default NavMain
