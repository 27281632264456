import React from 'react'
import logo2 from 'images/logo@2x.gif'
import logo3 from 'images/logo@3x.gif'
import css from 'common/marketingHeader/styles'
import agents2 from 'images/elizabethtown-ky-realtors-2x.jpg'
import agents3 from 'images/elizabethtown-ky-realtors-3x.jpg'

const MarketingHeader = () => (
  <header className={css.marketingHeader}>
    <a href="/" rel="home">
      <img
        alt="Real Estate near Fort Knox Kentucky"
        src={logo2}
        srcSet={`${logo3} 3x`}
      />
    </a>

    <a href="/contact-hardinhomes-fort-knox-agents">
      <img
        alt="Elizabethtown KY Realtors"
        src={agents2}
        srcSet={`${agents3} 3x`}
      />
    </a>
  </header>
)

export default MarketingHeader
